import React, { memo } from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from './Button'

const StyledDropzone = styled(Dropzone)`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: 6px;
  }
`

function UploadButton({
  children,
  onUpload,
  onReject,
  accept,
  disabled,
  multiple,
  maxSize,
  onClick,
  ...rest
}) {
  return (
    <Button {...rest} disabled={disabled}>
      <StyledDropzone
        multiple={multiple}
        onDropAccepted={onUpload}
        onDropRejected={onReject}
        onClick={onClick}
        accept={accept}
        disabled={disabled}
        noDrag
        maxSize={maxSize}
      >
        {children}
      </StyledDropzone>
    </Button>
  )
}

export default memo(UploadButton)

UploadButton.propTypes = Object.assign({}, Button.propTypes, {
  onUpload: PropTypes.func.isRequired
})
