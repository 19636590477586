import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { store, view } from 'react-stax'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { lighten } from 'polished'
import { ChevronDown } from '../icons'
import Button from './Button'
import Layout from '../../common/components/Layout'

const MAX_HEIGHT = 135
const NONE_KEY = '__none'

const StyledSelect = styled.label`
  position: relative;

  .label {
    font-weight: bold;
  }

  .top-label {
    margin-bottom: ${({ theme }) => theme.spacing.unit}px;
  }

  .select {
    width: 100%;
    justify-content: space-between;
    height: ${({ height }) => height || 32}px;
    padding: 0 ${({ theme }) => theme.spacing.unit}px;
    border: ${({ borderless, color, theme }) =>
    !borderless && `1px solid ${theme.color[color]}`};
    border-radius: 3px;
    white-space: pre;

    :active,
    :focus,
    :hover {
      border: ${({ borderless, theme }) =>
    !borderless && `1px solid ${theme.color.primary}`};
    }

    :before {
      content: '';
      position: absolute;
      top: -10px;
      left: -10px;
      width: 100%;
      height: 100%;
      border: 10px solid transparent;
      background-clip: padding-box;
    }
  }

  .subtext {
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 11px;
    color: ${({ theme, color }) => theme.color[color]};
    text-align: right;
    margin-top: 2px;
  }

  svg {
    width: 18px;
  }
`

const StyledOptions = styled(Layout)`
  position: fixed;
  top: ${({ optionsY }) => optionsY}px;
  left: ${({ optionsX }) => optionsX}px;
  width: ${({ optionsWidth }) => optionsWidth}px;
  transform: translateY(${({ openUpwards }) => (openUpwards ? '-100%' : 0)});
  max-height: ${MAX_HEIGHT}px;
  z-index: 10000;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  box-shadow: ${({ theme }) => transparentize(0.8, theme.color.black)} 0 2px
    12px;

  > * {
    min-height: 32px;
  }
`

class Select extends Component {
  store = store({
    isOpen: false
  })

  onOptionClick = value => {
    const { name, onChange } = this.props

    onChange(value === NONE_KEY ? undefined : value, name)
    this.closeOptions()
  }

  closeOptions = () => {
    // TODO: why is setTimeout needed here?
    // toggleOptions is also called on options click sometimes
    setTimeout(() => (this.store.isOpen = false))
  }

  toggleOptions = ev => {
    const { bottom, left, width } = ev.currentTarget.getBoundingClientRect()
    // do not let the options list overflow out of the window at the bottom
    this.store.openUpwards = window.innerHeight - MAX_HEIGHT < bottom
    this.store.optionsY = bottom + 10
    this.store.optionsX = left
    this.store.optionsWidth = width
    this.store.isOpen = !this.store.isOpen
  }

  render() {
    let {
      data,
      value,
      idKey,
      labelKey,
      label,
      placeholder,
      required,
      strict,
      compact,
      renderItem,
      error,
      ...rest
    } = this.props
    const { isOpen, optionsX, optionsY, optionsWidth, openUpwards } = this.store
    const selectedItem = data.find(item => item[idKey] === value)

    const showTopLabel = Boolean(!compact && label)
    const showInnerLabel = Boolean(compact && label)
    const color = error ? 'danger' : 'text'

    if (!strict) {
      data = [
        {
          [idKey]: NONE_KEY,
          [labelKey]: placeholder
        },
        ...data
      ]
    }

    return (
      <StyledSelect {...rest} onMouseLeave={this.closeOptions} color={color}>
        {showTopLabel && (
          <div className="label top-label">
            {label}
            {required && '*'}
          </div>
        )}
        <Button
          variant="text"
          className="select"
          active={isOpen}
          onClick={this.toggleOptions}
        >
          {showInnerLabel && <span className="label">{label}</span>}
          {selectedItem ? selectedItem[labelKey] : placeholder}
          <ChevronDown />
        </Button>
        {error && <div className="subtext">{error}</div>}
        {isOpen &&
          createPortal(
            <StyledOptions
              spacing="none"
              optionsX={optionsX}
              optionsY={optionsY}
              optionsWidth={optionsWidth}
              openUpwards={openUpwards}
            >
              {data.map(item => (
                <Button
                  variant="list-item"
                  key={item[idKey]}
                  value={item[idKey]}
                  active={item === selectedItem}
                  onClick={() => this.onOptionClick(item[idKey])}
                >
                  {renderItem ? renderItem(item) : item[labelKey]}
                </Button>
              ))}
            </StyledOptions>,
            document.getElementById('select-options')
          )}
      </StyledSelect>
    )
  }
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  labelKey: PropTypes.string.isRequired,
  idKey: PropTypes.string.isRequired,
  borderless: PropTypes.bool,
  renderItem: PropTypes.func,
  placeholder: PropTypes.string
}

Select.defaultProps = {
  placeholder: 'Select...',
  borderless: false
}

export default view(Select)
