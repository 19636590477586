import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import App from './App'
import theme from '../common/theme'
import '../common/font.css'

export const AppStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    border: 0;
    padding: 0 20px;
    margin: 0;
    background-color: ${theme.color.background};
    color: ${theme.color.text};
  }

  button,
  input,
  select {
    appearance: none;
  }

  ::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0px;
    background: transparent;
  }

  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
    box-sizing: border-box;
  }
`

export default function init() {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <>
        <App />
        <AppStyle />
      </>
    </ThemeProvider>,
    document.getElementById('root')
  )
}
