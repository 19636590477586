import React from 'react'
import styled, { keyframes } from 'styled-components'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import { view, store } from 'react-stax'

const loaderStore = store({
  isLoading: false
})

let loaders = 0

export function startLoading() {
  loaders++
  loaderStore.isLoading = true
}

export function stopLoading() {
  loaders--
  if (loaders === 0) {
    loaderStore.isLoading = false
  }
}

const loaderAnimation = keyframes`
  0% {
    left: 0;
    opacity: 0;
    transform: translateX(0);
  }
  10 % {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 100%;
    transform: translateX(-100%);
  }
`

const StyledLoader = styled.div`
  position: absolute;
  left: 0;
  width: 30%;
  min-width: 200px;
  background: linear-gradient(
    to right,
    ${({ theme }) => transparentize(1, theme.color.primary)} 0%,
    ${({ theme }) => theme.color.primary} 20%,
    ${({ theme }) => theme.color.primary} 80%,
    ${({ theme }) => transparentize(1, theme.color.primary)} 100%
  );
  height: 3px;
  animation: ${loaderAnimation} 1s infinite;
  z-index: 150;

  &.bottom {
    bottom: 0;
  }

  &:not(.bottom) {
    top: 0;
  }
`

function Loader({ className, bottom, ...rest }) {
  className = classNames(className, { bottom })

  return loaderStore.isLoading ? (
    <StyledLoader className={className} {...rest} />
  ) : null
}

export default view(Loader)
