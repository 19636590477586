import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { view } from 'react-stax'
import { Check as CheckIcon } from '../icons'
import { transparentize } from 'polished'

const StyledCheck = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: inherit;
  transition: color 0.2s, border-color 0.2s;

  :hover .checkbox {
    border: 1px solid ${({ theme }) => theme.color.primary};
  }

  .checkbox {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid ${({ theme }) => theme.color.text};
    border-radius: 3px;
    margin-right: ${({ theme }) => theme.spacing.unit}px;
    background-color: inherit;
  }

  .check {
    font-size: 14px;
    color: ${({ theme }) => theme.color.primary};
  }

  &.disabled {
    color: ${({ theme }) => theme.color.disabled};

    :hover .checkbox {
      border-color: ${({ theme }) => theme.color.disabled};
    }

    .checkbox {
      border: 1px solid ${({ theme }) => theme.color.disabled};
      background-color: ${({ theme }) =>
        transparentize(0.5, theme.color.disabled)};
    }

    .check {
      color: ${({ theme }) => theme.color.disabled};
    }
  }
`

class Check extends Component {
  onClick = () => {
    const { checked, disabled, onChange } = this.props

    if (!disabled) {
      onChange(!checked)
    }
  }

  render() {
    const { label, required, disabled, checked, ...rest } = this.props
    let { className } = this.props

    className = classNames(className, { disabled })

    return (
      <StyledCheck {...rest} className={className} onClick={this.onClick}>
        <span className="checkbox">
          {checked && <CheckIcon className="check" />}
        </span>
        <span>
          {label}
          {required && '*'}
        </span>
      </StyledCheck>
    )
  }
}

export default view(Check)

Check.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any
}
