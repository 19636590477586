import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { view, store } from 'react-stax'
import Layout from '../../common/components/Layout'
import Button from './Button'
import { Close } from '../icons'

const DURATION = 5000
const RECHECK_INTERVAL = 2000
const ANIMATION_DURATION = 250
const MAX_NOTIFICATIONS = 3

const slideInUp = keyframes`
  from {
    transform: translate(-50%, 100%);
    opacity: 0.5;
  }

  to {
    transform: translate(-50%, -20px);
    opacity: 1;
  }
`

const StyledNotification = styled(Layout)`
  position: fixed;
  bottom: ${({ idx }) => idx * 50}px;
  left: 50%;
  transform: translate(-50%, -20px);
  padding: 10px 20px;
  border: ${({ theme, color }) => `1px solid ${theme.color[color]};`}
  border-radius: 5px;
  box-shadow: 0 2.5px 20px rgba(50, 50, 50, 0.3);
  background-color: ${({ theme, color }) => theme.color[color]};
  z-index: 2000;
  text-align: center;
  animation: ${slideInUp} ${ANIMATION_DURATION}ms;
  transition: bottom ${ANIMATION_DURATION}ms, opacity ${ANIMATION_DURATION /
  2}ms;
  font-weight: bold;
  color: white;
  opacity: ${({ closed }) => (closed ? 0 : 1)};

  svg {
    width: 20px;
    height: 20px;
  }
`

let incrId = 0
const notifications = store([])

export function notify({ message, color = 'primary' }) {
  const id = incrId++
  const timeout = setTimeout(() => closeNotification(id), DURATION)

  // wrap the notification in a store here too
  // this makes sure that the retunred notification is a reactive one
  // it is updated if the user updates its content
  const notification = store({ message, color, timeout, id })

  if (
    !notifications.some(
      notif => notif.message === message && notif.color === color
    )
  ) {
    // TODO: switch to async batched react stax
    ReactDOM.unstable_batchedUpdates(() => {
      notifications.unshift(notification)
      if (MAX_NOTIFICATIONS <= notifications.length) {
        notifications.length = MAX_NOTIFICATIONS
      }
    })
  }
  // return the reactive (Proxy wrapped) notification
  return notification
}

function closeNotification(id, force) {
  const idx = notifications.findIndex(notif => notif.id === id)
  const notification = notifications[idx]

  if (notification) {
    clearTimeout(notification.timeout)

    if (notification.closed) {
      notifications.splice(idx, 1)
    } else if (notification.hovered && !force) {
      // check again in X seconds if the notification is currently hovered by the user
      notification.timeout = setTimeout(
        () => closeNotification(id),
        RECHECK_INTERVAL
      )
    } else {
      notification.closed = true
      notification.timeout = setTimeout(
        () => closeNotification(id),
        ANIMATION_DURATION / 2
      )
    }
  }
}

const Notification = view(
  class Notification extends Component {
    onMouseEnter = () => {
      this.props.notification.hovered = true
    }

    onMouseLeave = () => {
      this.props.notification.hovered = false
    }

    render() {
      const { notification, idx, ...rest } = this.props
      const { message, color, id, closed } = notification

      return (
        <StyledNotification
          {...rest}
          idx={idx}
          closed={closed}
          variant="horizontal"
          spacing="dense"
          color={color}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <div>{message}</div>
          <Button
            variant="text"
            color="white"
            onClick={() => closeNotification(id, true)}
          >
            <Close />
          </Button>
        </StyledNotification>
      )
    }
  }
)

function Notifications() {
  return (
    <>
      {notifications.map((notification, idx) => (
        <Notification
          key={notification.id}
          notification={notification}
          idx={idx}
        />
      ))}
    </>
  )
}

export default view(Notifications)
