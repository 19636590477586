import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { view, store, route, params } from 'react-stax'
import userStore from '../../stores/user'
import { Input, Form, Button, notify } from '../../components'

const StyledRegister = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .form {
    width: 300px;
  }

  .title {
    font-size: 24px;
    font-weight: 200;
    color: ${({ theme }) => theme.color.lightGray};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 6}px;
  }

  .to-login {
    cursor: pointer;
    color: ${({ theme }) => theme.color.lightGray};
    white-space: pre;
    text-align: center;
    line-height: 1.5;
  }
`
class Register extends Component {
  store = store({
    data: {
      name: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    errors: {}
  })

  componentDidMount() {
    try {
      const parsed = JSON.parse(atob(params.token.split('.')[1]))
      this.store.data = Object.assign(this.store.data, parsed)
    } catch (err) {
      notify({
        message: 'Invalid registration link. Please ask for a new invitation!',
        color: 'danger'
      })
      this.toLogin()
    }
  }

  onChange = ev => {
    const { name, value } = ev.target
    this.store.data[name] = value
  }

  onSubmit = async () => {
    const { name, email, password, role } = this.store.data
    const { token } = params
    await userStore.register({ name, email, password, role, token })
    route({ to: 'admin' })
  }

  toLogin = () => {
    route({ to: 'login' })
  }

  render() {
    const { data, errors } = this.store

    return (
      <StyledRegister>
        <Form
          className="form"
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          errors={errors}
          data={data}
          schema="users.register.client"
        >
          <h2 className="title">Register Account</h2>
          <Input
            name="name"
            value={data.name}
            error={errors.name}
            label="Name"
            required
            autoFocus
            autoComplete="name"
          />
          <Input
            name="email"
            value={data.email}
            error={errors.email}
            label="Email Address"
            required
            // correct={!errors.email && data.email && 'Jeszenák János'}
            autoComplete="email"
          />
          <Input
            name="password"
            value={data.password}
            error={errors.password}
            type="password"
            label="Password"
            required
            autoComplete="new-password"
          />
          <Input
            name="confirmPassword"
            value={data.confirmPassword}
            error={errors.confirmPassword}
            type="password"
            label="Confirm Password"
            required
            autoComplete="new-password"
          />
          <Button variant="raised" type="submit" errors={errors}>
            REGISTER
          </Button>
          <div className="to-login" onClick={this.toLogin}>
            {'Already have an account?\nClick here to login.'}
          </div>
        </Form>
      </StyledRegister>
    )
  }
}

export default view(Register)
