import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Layout from '../../common/components/Layout'

const StyledBadge = styled(Layout)`
  background-color: ${({ theme, color }) => theme.color[color]};
  color: ${({ theme }) => theme.color.white};
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 12px;
`

function Badge({ children, ...rest }) {
  return (
    <StyledBadge {...rest} variant="center">
      {children}
    </StyledBadge>
  )
}

export default memo(Badge)

Badge.propTypes = {
  color: PropTypes.oneOf(['primary', 'danger', 'warning', 'success'])
}

Badge.defaultProps = {
  color: 'primary'
}
