import React, { forwardRef } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { view } from 'react-stax'
import { transparentize, darken, lighten } from 'polished'
import PropTypes from 'prop-types'
import Layout from '../../common/components/Layout'
import Tooltip from './Tooltip'

const StyledButton = styled.button`
  border: none;
  outline: none;
  font-size: inherit;
  font-family: inherit;
  white-space: pre;
  background-color: inherit;
  color: inherit;
  padding: 0;
  transition: color 0.2s, background-color 0.2s;
  cursor: pointer;
  text-decoration: none;
  :disabled {
    cursor: not-allowed;
  }

  &.basic {
    /* add basic styling here in the future */
  }

  &.panel {
    position: relative;
    display: inline-block;
    padding: 0;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.color.shadow} 0 0px 2px;
    transition: box-shadow 0.2s;
    outline: none;

    :hover, &.active {
      box-shadow: ${({ theme, color }) => theme.color[color]} 0 0px 3px;
      color: ${({ theme, color }) => theme.color[color]};
      stroke: ${({ theme, color }) => theme.color[color]};
    }
  }

  &.list-item {
    padding-left: 10px;
    padding-right: 10px;
    height: 32px;
    width: 100%;

    &.active {
      background-color: ${({ theme }) => theme.color.listItem};
    }

    :hover:not(.active) {
      background-color: ${({ theme }) =>
        transparentize(0.4, theme.color.listItem)};
    }
  }

  &.text {
    color: ${({ color, theme }) =>
      color === 'disabled' ? theme.color[color] : 'inherit'};
    stroke-width: 1;
    :not(:disabled) {
      :hover,
      :active,
      &.active {
        color: ${({ theme, color }) => theme.color[color]};
        stroke: ${({ theme, color }) => theme.color[color]};
      }
      :hover:not(:active) svg {
        filter: drop-shadow(
          0 0 2px ${({ theme, color }) =>
            transparentize(0.7, theme.color[color])}
        );
      }
    }
    :disabled {
      color: ${({ theme }) => theme.color.disabled};
      stroke: ${({ theme }) => theme.color.disabled};
    }
  }

  &.bordered {
    min-width: 100px;
    border-radius: 5px;
    color: ${({ theme, color }) => theme.utils.textColor(theme.color[color])};
    border: solid 2px ${({ theme, color }) =>
      theme.utils.textColor(theme.color[color])};
    padding: 3px 10px;
    font-weight: bold;
    :hover:not(:active):not(:disabled) {
      box-shadow: ${({ theme, color }) =>
        transparentize(0.2, theme.utils.textColor(theme.color[color]))}
        0 0 6px;
    }
  }

  &.raised {
    min-width: 120px;
    height: 32px;
    border-radius: 5px;
    background-color: ${({ theme, color }) => theme.color[color]};
    color: ${({ theme, color }) => theme.utils.textColor(theme.color[color])};
    box-shadow: ${({ theme }) => theme.color.shadow} 0 4px 8px;
    padding: 0 ${({ theme }) => theme.spacing.unit * 3}px;
    font-weight: bold;
    :hover:not(:active):not(:disabled) {
      box-shadow: ${({ theme, color }) =>
        transparentize(0.2, theme.color[color])}
        0 0 6px;
    }
    /*:active,
    &.active {
      box-shadow: 0 0 0 3px
        ${({ color, theme }) => darken(0.05, theme.color[color])};
    }*/
    :disabled {
      background-color: ${({ theme }) => theme.color.disabled};
    }

    &.small {
      height: 24px;
      min-width: 0;
    }
  }
  &.flat {
    border-radius: 5px;
    color: ${({ theme, color }) => theme.utils.textColor(theme.color[color])};
    font-weight: bold;

    :not(:disabled) {
      background-color: ${({ theme, color }) => theme.color[color]};
    }
    :disabled {
      background-color: ${({ theme }) => theme.color.disabled};
    }
  }
  &.underlined {
    :not(:disabled) {
      :hover,
      :active,
      &.active {
        text-decoration: underline;
        text-decoration-color: ${({ theme, color }) => theme.color[color]};
      }
    }
    :disabled {
      color: ${({ theme }) => theme.color.disabled};
    }
  }

  &.uppercase {
    text-transform: uppercase;
    font-weight: bold;
  }
`

// do not change focus on button clicks
function preventDefault(ev, originalOnMouseDown) {
  if (originalOnMouseDown) {
    originalOnMouseDown(ev)
  }
  ev.preventDefault()
}

function _Button({
  children,
  variant,
  active,
  color,
  className,
  disabled,
  tooltip,
  errors,
  onMouseDown,
  element,
  small,
  uppercase,
  layout,
  spacing,
  innerRef,
  ...rest
}) {
  className = classNames(className, variant, { active, small, uppercase })
  disabled = disabled || Object.keys(errors).length > 0

  if (!layout) {
    if (variant === 'list-item' || variant === 'text') {
      layout = 'horizontal'
    } else {
      layout = 'center'
    }
  }

  const button = (
    // allow the other props (...rest) to overwtire the type
    // do not submit forms by default
    <StyledButton
      type="button"
      {...rest}
      ref={innerRef}
      as={element}
      className={className}
      color={color}
      disabled={disabled}
      onMouseDown={ev => preventDefault(ev, onMouseDown)}
    >
      <Layout variant={layout} spacing={spacing}>
        {children}
      </Layout>
    </StyledButton>
  )

  if (tooltip) {
    return <Tooltip message={tooltip}>{button}</Tooltip>
  } else {
    return button
  }
}

_Button.propTypes = {
  variant: PropTypes.oneOf([
    'raised',
    'text',
    'underlined',
    'icon',
    'flat',
    'basic',
    'panel',
    'list-item',
    'bordered'
  ]),
  color: PropTypes.oneOf([
    'primary',
    'danger',
    'warning',
    'success',
    'white',
    'disabled',
    'text'
  ]),
  small: PropTypes.bool,
  uppercase: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  layout: Layout.propTypes.variant,
  spacing: Layout.propTypes.spacing,
  errors: PropTypes.object,
  element: PropTypes.string
}

_Button.defaultProps = {
  variant: 'raised',
  color: 'primary',
  element: 'button',
  spacing: 'tight',
  errors: {}
}

const Button = view(_Button)

export default forwardRef((props, ref) => <Button {...props} innerRef={ref} />)
