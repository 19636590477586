import { storage } from 'react-stax'
import api from './api'

function processUser(data) {
  api.defaults.headers.Authorization = `Bearer ${data.token}`
  storage.token = data.token
  return data.user
}

export async function login(loginData) {
  const { data } = await api.post('/login', loginData)
  return processUser(data)
}

export async function register(registerData) {
  const { data } = await api.post('/register', registerData)
  return processUser(data)
}

export function logout() {
  delete api.defaults.headers.Authorization
  delete storage.token
}

export async function getMe() {
  const { data } = await api.get('/users/me')
  return data
}

export async function list(search) {
  const { data } = await api.get('/users', { params: search })
  return data
}

export async function update(updateData) {
  const { data } = await api.put('/users/me', updateData)
  return data
}

export async function invite(user) {
  const { data } = await api.post('/users/invite', user)
  return data
}

export async function remove(id) {
  const { data } = await api.delete(`/user/${id}`)
  return data
}
