import React, { memo } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { Close as CloseIcon } from '../icons'
import classNames from 'classnames'
import { transparentize, lighten } from 'polished'
import Button from './Button'
import Backdrop from './Backdrop'

const StyledModal = styled.div`
  position: fixed;
  min-width: 300px;
  max-width: 95vw;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  z-index: 130;
  background-color: ${({ theme, success }) =>
    success ? lighten(0.15, theme.color.success) : theme.color.white};
  box-shadow: ${({ theme }) => transparentize(0.94, theme.color.black)} 0 6px
    12px;
  padding: ${({ theme }) => theme.spacing.unit * 4}px;
  border-radius: 5px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;

    .text {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      margin-right: ${({ theme }) => theme.spacing.unit * 8}px;
    }

    .close-button {
      position: relative;
      right: 0;
      top: 0;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }
`

function Modal({
  children,
  title,
  open,
  onClose,
  className,
  success,
  ...rest
}) {
  if (!open) {
    return null
  }

  const modalClass = classNames(className, 'modal')

  return ReactDOM.createPortal(
    <>
      <StyledModal {...rest} className={modalClass} success={success}>
        <div className="title">
          <h3 className="text">{title}</h3>
          <Button
            className="close-button"
            variant="text"
            color="danger"
            onClick={onClose}
          >
            <CloseIcon />
          </Button>
        </div>
        {children}
      </StyledModal>
      <Backdrop />
    </>,
    document.getElementById('modal')
  )
}

export default memo(Modal)

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  success: PropTypes.bool
}

Modal.defaultProps = {
  success: false
}
