import React from 'react'
import PropTypes from 'prop-types'

export default function Line({
  from,
  to,
  color,
  opacity,
  width,
  style,
  gradient,
  lengthRatio,
  zIndex,
  reversed
}) {
  let switched = false
  if (reversed) {
    const cachedFrom = from
    from = to
    to = cachedFrom
    switched = true
  }

  // avoid mutating the passed in prop objects (from, to)
  const fromX = from.x
  const fromY = from.y - width / 2
  const toX = to.x
  const toY = to.y - width / 2

  const length = Math.sqrt(Math.pow(fromX - toX, 2) + Math.pow(fromY - toY, 2))
  // round the length upwards to compensate for rounding errors
  // a line with a bit more length looks better than a small gap between two lines
  const drawnLength = Math.ceil(length * lengthRatio)

  let angle = Math.atan((toY - fromY) / (toX - fromX))
  if (to.x < from.x) {
    angle += Math.PI
  }

  const lineStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(${fromX -
      0.5 * drawnLength * (1 - Math.cos(angle))}px, ${fromY +
      0.5 * drawnLength * Math.sin(angle)}px) rotate(${angle}rad)`,
    width: `${drawnLength}px`,
    opacity,
    zIndex,
    pointerEvents: 'none'
  }

  // support either gradient or dashed lines
  if (gradient) {
    const direction = switched ? 'left' : 'right'
    lineStyle.height = `${width}px`
    lineStyle.background = `linear-gradient(to ${direction}, transparent, ${color})`
  } else {
    lineStyle.borderBottom = `${width}px ${style} ${color}`
  }

  return <div style={lineStyle} />
}

Line.propTypes = {
  from: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired,
  to: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired,
  color: PropTypes.string,
  opacity: PropTypes.number,
  gradient: PropTypes.bool,
  width: PropTypes.number,
  zIndex: PropTypes.number,
  style: PropTypes.oneOf(['solid', 'dashed', 'dotted']),
  reversed: PropTypes.bool,
  lengthRatio: PropTypes.number
}

Line.defaultProps = {
  color: 'gray',
  opacity: 1,
  width: 2,
  zIndex: 1,
  style: 'solid',
  gradient: false,
  lengthRatio: 1,
  reversed: false
}
