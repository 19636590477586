import React, { Component, Fragment, isValidElement } from 'react'
import { isElement } from 'react-is'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { store, view } from 'react-stax'
import { transparentize } from 'polished'

const background = '#1F1F1F'
const HOVER_BUFFER = 18

const StyledMenu = styled.div`
  position: fixed;
  z-index: 500;
  padding: 15px;
  cursor: pointer;
  top: ${({ top, topOffset }) => top + topOffset - 15}px;
  left: ${({ left }) => left + 7}px;
  min-width: 140px;
  background-color: ${background};
  color: ${({ theme }) => theme.color.white};
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.shadow} 0 5px 10px;
  padding: ${({ theme }) => theme.spacing.unit}px 0;
  transition: color 0.2s, background-color 0.2s;

  :before {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    content: '';
    top: ${({ topOffset }) => 7 - topOffset}px;
  }
}

&.left {
  left: ${({ left }) => left - 7}px;
  transform: translateX(-100%);

  &:before {
    right: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid ${background};

    &.disabled {
      &:before {
        border-left: 8px solid ${({ theme }) => theme.color.disabled};
      }
    }
  }
}

&:not(.left) {
  &:before {
    left: -8px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid ${background};

    &.disabled {
      &:before {
        border-right: 8px solid ${({ theme }) => theme.color.disabled};
      }
    }
  }
}

&.disabled {
  background-color: ${({ theme }) => theme.color.disabled};
}

.hover-buffer {
  position: absolute;
  top: ${-HOVER_BUFFER / 2}px;
  left: ${-HOVER_BUFFER / 2}px;
  width: calc(100% + ${HOVER_BUFFER}px);
  height: calc(100% + ${HOVER_BUFFER}px);
  background-color: transparent;
  z-index: -1;
}
`

const reactEvents = {
  hover: 'onMouseEnter',
  click: 'onClick',
  rightclick: 'onContextMenu'
}

const nativeEvents = {
  hover: 'mouseenter',
  click: 'click',
  rightclick: 'contextmenu'
}

class Menu extends Component {
  store = store({
    open: false,
    top: 0,
    left: 0,
    topOffset: 0
  })

  constructor(props) {
    super(props)

    const { button, trigger } = props

    if (isValidElement(button)) {
      // React button
      this.type = 'react'
      this.Trigger = ({ children }) =>
        React.createElement(
          'div',
          {
            [reactEvents[trigger]]: this.onOpen
          },
          children
        )
    } else if (button instanceof Element) {
      // native DOM button
      this.type = 'dom'
      button.addEventListener(nativeEvents[trigger], this.onOpen)
    } else {
      // mapbox map
      this.type = 'mapbox'
      button.on(nativeEvents[trigger], this.onOpen)
    }
  }

  componentWillUnmount() {
    const { button, trigger } = this.props
    if (this.type === 'dom') {
      button.removeEventListener(nativeEvents[trigger], this.onOpen)
    } else if (this.type === 'mapbox') {
      button.off(nativeEvents[trigger], this.onOpen)
    }
  }

  onOpen = ev => {
    const { onOpen, numOfItems } = this.props

    if (onOpen) {
      onOpen(ev)
    }

    if (this.type === 'mapbox') {
      ev = ev.originalEvent
    }

    this.store.open = true
    this.store.left = ev.clientX
    this.store.top = ev.clientY
    this.store.openLeft = window.innerWidth - ev.clientX < 150

    const menuHeight = numOfItems * 32 + 20
    const availableHeight = window.innerHeight - ev.clientY
    this.store.topOffset = Math.min(0, availableHeight - menuHeight)
    ev.preventDefault()
  }

  onClose = () => {
    const { onClose } = this.props

    this.store.open = false

    if (onClose) {
      onClose()
    }
  }

  render() {
    const {
      children,
      trigger,
      button,
      disabled,
      className,
      ...rest
    } = this.props
    let { open, top, left, topOffset, openLeft } = this.store

    const Trigger = this.Trigger
    const menuClass = classNames(className, { left: openLeft, disabled })

    open = Boolean(open && children)

    return (
      <>
        {Trigger && <Trigger>{button}</Trigger>}
        {open &&
          ReactDOM.createPortal(
            <StyledMenu
              {...rest}
              className={menuClass}
              top={top}
              left={left}
              topOffset={topOffset}
              onClick={this.onClose}
              onMouseLeave={this.onClose}
            >
              {children}
              <div className="hover-buffer" />
            </StyledMenu>,
            document.getElementById('context-menu')
          )}
      </>
    )
  }
}

export default view(Menu)

Menu.propTypes = {
  button: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
  trigger: PropTypes.oneOf(['click', 'rightclick', 'hover']),
  disabled: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
}

Menu.defaultProps = {
  trigger: 'click',
  numOfItems: 0
}
