import { parseToHsl, lighten, transparentize } from 'polished'

const highlight = '#ccd4ff'

const theme = {
  color: {
    primary: '#3451ff',
    success: '#40CB49',
    danger: '#ec3a58',
    warning: '#ffaa00',
    background: '#f4f4f4',
    text: '#131313',
    highlight,
    disabled: '#666666',
    white: '#ffffff',
    black: '#323232',
    lightGray: '#999999',
    shadow: 'rgba(0, 0, 0, 0.2)',
    listItem: lighten(0.05, highlight),
    brand: '#ff7700',
    brandDark: '#ffbb00'
  },
  palette: {
    dark: [
      '#ffbb00',
      '#66ff66',
      '#33ffcc',
      '#99ffff',
      '#66ccff',
      '#cccccc',
      '#ffccaa',
      '#ccaaff',
      '#ff99ff',
      '#ffffff'
    ],
    light: [
      '#ff7700',
      '#33aa33',
      '#33aa99',
      '#0099ff',
      '#0033ff',
      '#999999',
      '#ff3300',
      '#9933ff',
      '#ff33ff'
    ]
  },
  spacing: {
    unit: 5.5
  },
  utils: {
    textColor(background) {
      const { lightness } = parseToHsl(background)
      return lightness < 0.7 ? theme.color.white : theme.color.text
    },
    backgroundColor(color) {
      const { lightness } = parseToHsl(color)
      return transparentize(
        0.4,
        lightness < 0.7 ? theme.color.white : '#bbbbbb'
      )
    },
    getHighlight(isDark) {
      const { primary } = theme.color

      return isDark ? lighten(0.15, primary) : primary
    }
  }
}

export default theme
