import { store } from 'react-stax'
import * as userApi from '../api/users'

export const roles = { ADMIN: 1, EDITOR: 2 }

const user = store({
  isProfileOpen: false,
  async init() {
    user.profile = await userApi.getMe()
  },
  async login(loginData) {
    user.profile = await userApi.login(loginData)
  },
  async register(registerData) {
    user.profile = await userApi.register(registerData)
  },
  logout() {
    userApi.logout()
    user.profile = undefined
  },
  async update(data) {
    Object.assign(user.profile, await userApi.update(data))
  },
  openProfile() {
    user.isProfileOpen = true
  },
  closeProfile() {
    user.isProfileOpen = false
  }
})

export default user
