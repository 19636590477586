import React, { Component, Fragment } from 'react'
import { view, store } from 'react-stax'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Check, Close, Plus, Minus } from '../icons'
import Layout from '../../common/components/Layout'
import Button from './Button'
import Input from './Input'

const StyledStepper = styled(Layout)`
  width: 100%;
  justify-content: stretch;
  align-items: flex-end !important;

  .input {
    position: relative;
    flex: 1;

    input {
      text-align: center;
    }
  }

  .buttons {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 7px #ccc;
    overflow: hidden;
    margin-bottom: 2px;

    svg {
      width: 12px;
      height: 12px;
    }
    .button {
      font-size: 16px;
      width: 15px;
      height: 17px;
    }
    .left-button {
      border-right: 1px solid #ccc;
    }
  }
`

function formatValue({ value, prevValue, min, max, precision }) {
  value = Number.parseFloat(value)
  value = Number.parseFloat(value.toFixed(precision))
  value = Math.min(max, Math.max(min, value))
  return Number.isNaN(value) ? prevValue : value
}

const LONG_PRESS_THRESHOLD = 500
const LONG_PRESS_INTERVAL = 200

class Stepper extends Component {
  input = React.createRef()

  store = store({
    isFocused: false,
    value: formatValue({ ...this.props, prevValue: 0 }),
    prevValue: 0
  })

  static deriveStoresFromProps(props, store) {
    // save the new values if the props changed
    if (props.value !== store.prevValue) {
      store.value = formatValue({ ...props, prevValue: store.prevValue })
      store.prevValue = props.value
    }
  }

  onChange = ev => {
    this.store.value = ev.target.value
  }

  increment = () => {
    const { onChange, value, step, max } = this.props
    const nextValue = value + step
    if (nextValue < max) {
      onChange(nextValue)
    } else {
      onChange(max)
      this.clearLongPress()
    }
  }

  onIncrement = ev => {
    if (ev.button !== 0) {
      return
    }

    this.increment()
    this.longPressId = setTimeout(() => {
      this.longPressId = setInterval(this.increment, LONG_PRESS_INTERVAL)
    }, LONG_PRESS_THRESHOLD)
  }

  decrement = () => {
    const { onChange, value, step, min } = this.props
    const nextValue = value - step
    if (min < nextValue) {
      onChange(nextValue)
    } else {
      onChange(min)
      this.clearLongPress()
    }
  }

  onDecrement = ev => {
    if (ev.button !== 0) {
      return
    }

    this.decrement()
    this.longPressId = setTimeout(() => {
      this.longPressId = setInterval(this.decrement, LONG_PRESS_INTERVAL)
    }, LONG_PRESS_THRESHOLD)
  }

  clearLongPress = () => {
    clearTimeout(this.longPressId)
  }

  onFocus = () => {
    this.store.isFocused = true
  }

  onBlur = () => {
    this.store.isFocused = false
    this.store.value = this.store.prevValue
  }

  onApply = () => {
    const value = formatValue({
      ...this.props,
      prevValue: this.store.prevValue,
      value: this.store.value.replace(',', '.')
    })
    this.props.onChange(value)
    this.input.current.blur()
  }

  onEscape = () => {
    this.store.value = this.store.prevValue
    this.input.current.blur()
  }

  onKeyDown = ev => {
    if (ev.key === 'Enter') {
      this.onApply()
    } else if (ev.key === 'Escape') {
      this.onEscape()
    }
  }

  render() {
    const { isFocused, value } = this.store
    const { min, max, label, unit } = this.props
    const unitLength = unit ? unit.length : 0

    return (
      <StyledStepper
        variant="horizontal"
        spacing="tight"
        unitLength={unitLength}
      >
        <div className="input">
          <Input
            layout="horizontal"
            spacing="tight"
            innerRef={this.input}
            value={value}
            label={label}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            widgets={unit}
            compact
          />
        </div>
        <Layout variant="center" spacing="none" className="buttons">
          {isFocused ? (
            <>
              <Button
                variant="text"
                layout="center"
                color="danger"
                className="button left-button"
                onClick={this.onEscape}
              >
                <Close />
              </Button>
              <Button
                variant="text"
                layout="center"
                color="success"
                className="button"
                onClick={this.onApply}
              >
                <Check />
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="text"
                layout="center"
                className="button left-button"
                onMouseDown={this.onDecrement}
                onMouseUp={this.clearLongPress}
                disabled={value <= min}
              >
                <Minus />
              </Button>
              <Button
                variant="text"
                layout="center"
                className="button"
                onMouseDown={this.onIncrement}
                onMouseUp={this.clearLongPress}
                disabled={max <= value}
              >
                <Plus />
              </Button>
            </>
          )}
        </Layout>
      </StyledStepper>
    )
  }
}

export default view(Stepper)

Stepper.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  precision: PropTypes.number
}

Stepper.defaultProps = {
  step: 1,
  min: 0,
  max: Infinity,
  precision: 6
}
