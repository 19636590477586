import axios from 'axios'
import _get from 'lodash/get'
import { storage } from 'react-stax'
import { startLoading, stopLoading } from '../components/Loader'
import { notify } from '../components'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000
})

if (storage.token) {
  api.defaults.headers.Authorization = `Bearer ${storage.token}`
}

api.interceptors.request.use(config => {
  startLoading()
  config.intercepted = true
  return config
})

api.interceptors.response.use(
  resp => {
    if (resp.config.intercepted) {
      stopLoading()
    }
    return resp
  },
  err => {
    const message = _get(err, 'response.data.message')
    if (message) {
      notify({ message, color: 'danger' })
    }
    if (err.config.intercepted) {
      stopLoading()
    }
    // TODO: fix this, maybe never rethrowing would be the best
    // but I will have to adapt form validation to it
    const status = _get(err, 'response.status')
    if (status !== 403) {
      throw err
    }
  }
)

export default api
