import React, { PureComponent } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from './Button'
import Layout from '../../common/components/Layout'
import transparentImage from '../images/transparent.png'

const StyledSwatch = styled(Layout)`
  justify-content: flex-start !important;

  .swatch {
    position: relative;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 6px;
    display: inline-block;
    background: url(${transparentImage});
    box-shadow: ${({ theme }) => theme.color.shadow} 1px 1px 3px;
    transition: all 0.1s ease-in-out;

    :hover {
      box-shadow: ${({ theme }) => theme.color.shadow} 2px 2px 6px;
    }

    :before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background-color: ${({ color }) => color};
      opacity: ${({ opacity }) => opacity};
    }
  }
`

export default class Swatch extends PureComponent {
  onClick = ev => {
    const { color, opacity, onClick } = this.props
    ev.color = color
    ev.opacity = opacity
    onClick(ev)
  }

  render() {
    const { onClick, label, ...rest } = this.props

    return (
      <StyledSwatch {...rest} variant="horizontal" spacing="tight">
        {label && <label>{label}:</label>}
        <Button variant="basic" onClick={this.onClick} className="swatch" />
      </StyledSwatch>
    )
  }
}

Swatch.propTypes = {
  color: PropTypes.string.isRequired,
  opacity: PropTypes.number,
  size: PropTypes.number
}

Swatch.defaultProps = {
  opacity: 1,
  size: 24
}
