import styled from 'styled-components'

export default styled.h3`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.lightGray};
  margin: 0;
  padding: 0;
  white-space: pre;
`
