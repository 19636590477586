import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { view } from 'react-stax'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'
import Layout from '../../common/components/Layout'

const StyledForm = styled.form`
  .error {
    color: ${({ theme }) => theme.color.danger};
  }
`

class Form extends Component {
  touchedFields = new Set()

  get hasErrors() {
    return Object.keys(this.props.errors).length !== 0
  }

  onSubmit = async ev => {
    const { onSubmit, errors } = this.props

    if (ev) {
      ev.preventDefault()
    }
    await this.validate({ strict: true })
    this.touchedFields.clear()

    if (!this.hasErrors) {
      try {
        await onSubmit()
      } catch (err) {
        const fields = _get(err, 'response.data')
        if (fields) {
          Object.keys(errors).forEach(key => delete errors[key])
          Object.assign(errors, fields)
        }
      }
    }
  }

  onChange = ev => {
    const { onChange } = this.props
    if (onChange) {
      onChange(ev)
    }
    this.touchedFields.add(ev.target.name)
    this.validate({ strict: false })
  }

  validate = async ({ strict, fields = this.touchedFields } = {}) => {
    let { schema, errors, data } = this.props

    const schemas = await import('index_schemas')
    schema = _get(schemas, schema)

    // it is needed for the array conversion to support joi array checks
    data = _cloneDeep(data)
    for (let key in data) {
      if (data[key] instanceof Set) {
        data[key] = Array.from(data[key])
      }
    }

    if (data && schema) {
      try {
        schemas.validate(data, schema)
        fields.forEach(key => delete errors[key])
      } catch (err) {
        const details = err.fields
        fields.forEach(key => {
          if (strict) {
            errors[key] = details[key]
          }
          if (!details[key]) {
            delete errors[key]
          }
        })
      }
    }
  }

  onBlur = () => {
    this.validate({ strict: true })
  }

  render() {
    const { children, onSubmit, onChange, errors, ...rest } = this.props

    return (
      <StyledForm
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        onBlur={this.onBlur}
      >
        <Layout {...rest}>{children}</Layout>
        {errors.msg && <p className="error">{errors.msg}</p>}
      </StyledForm>
    )
  }
}

export default view(Form)

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  data: PropTypes.object,
  schema: PropTypes.string
}

Form.defaultProps = {
  errors: {}
}
