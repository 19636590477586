import React, { Component, createRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { store, view } from 'react-stax'
import Search from './Search'
import Button from './Button'
import Layout from '../../common/components/Layout'
import { lighten, transparentize } from 'polished'
import _debounce from 'lodash/debounce'
import _throttle from 'lodash/throttle'

const MAX_HEIGHT = 135

const StyledAutoSuggest = styled.div`
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 140%;
    border-bottom: 10px solid transparent;
    background-clip: padding-box;
  }
`

const StyledOptions = styled(Layout)`
  position: fixed;
  top: ${({ optionsY }) => optionsY}px;
  left: ${({ optionsX }) => optionsX}px;
  width: ${({ optionsWidth }) => optionsWidth}px;
  transform: translateY(${({ openUpwards }) => (openUpwards ? '-100%' : 0)});
  max-height: ${MAX_HEIGHT}px;
  z-index: 10000;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  box-shadow: ${({ theme }) => transparentize(0.8, theme.color.black)} 0 2px
    12px;

  > * {
    min-height: 32px;
  }
`

class AutoSuggest extends Component {
  options = createRef()

  store = store({
    isFocused: false,
    isOpen: false,
    selectedIdx: 0,
    value: ''
  })

  componentDidMount() {
    this.didMount = true
  }

  onKeyDown = ev => {
    const { selectedIdx } = this.store
    const { items } = this.props

    if (ev.key === 'ArrowUp') {
      this.store.selectedIdx = Math.max(selectedIdx - 1, 0)
    } else if (ev.key === 'ArrowDown') {
      this.store.selectedIdx = Math.min(selectedIdx + 1, items.length - 1)
    } else if (ev.key === 'Escape') {
      this.onClear()
    } else if (ev.key === 'Enter' && this.store.isOpen) {
      const item = items[selectedIdx]
      if (item) {
        this.onItemSelect(item)
      }
      ev.preventDefault()
    } else {
      // open the suggestions on normal keys
      this.store.isOpen = true
    }

    if (ev.key === 'ArrowUp' || ev.key === 'ArrowDown') {
      const options = this.options.current
      if (options) {
        const child = options.children[this.store.selectedIdx]
        if (child) {
          child.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }

  onChange = ev => {
    this.store.value = ev.target.value
  }

  onMouseLeave = () => {
    this.store.isOpen = false
  }

  onMouseEnter = () => {
    if (this.store.isFocused) {
      this.store.isOpen = true
    }
  }

  onFocus = ev => {
    const { target } = ev

    this.store.selectedIdx = 0
    this.store.isFocused = true

    // do not open the options the focus was an initial autoFocus event
    // which happens before react mounting
    if (this.didMount) {
      this.store.isOpen = true
    }

    const { bottom, left, width } = target.parentNode.getBoundingClientRect()
    // do not let the options list overflow out of the window at the bottom
    this.store.openUpwards = window.innerHeight - MAX_HEIGHT < bottom
    this.store.optionsY = bottom + 10
    this.store.optionsX = left
    this.store.optionsWidth = width
  }

  onBlur = () => {
    this.store.isFocused = false
    this.store.isOpen = false
  }

  onSearch = async () => {
    await this.props.onSearch(this.store.value)
    this.store.selectedIdx = 0
    this.store.isOpen = true
  }

  onClear = () => {
    this.store.selectedIdx = 0
    this.store.value = ''
    this.store.isOpen = false
  }

  onItemSelect = item => {
    const { closeOnSelect, onItemSelect } = this.props
    onItemSelect(item)
    if (closeOnSelect) {
      this.store.isOpen = false
    }
    // get back to the baseline search after a user select
    this.store.value = ''
    this.props.onSearch(this.store.value)
    this.store.selectedIdx = 0
  }

  render() {
    const { className, items, onItemSelect, renderItem, ...rest } = this.props
    const {
      isOpen,
      selectedIdx,
      value,
      optionsX,
      optionsY,
      optionsWidth,
      openUpwards
    } = this.store

    return (
      <StyledAutoSuggest ref={this.container} onMouseLeave={this.onMouseLeave}>
        <Search
          {...rest}
          innerRef={this.input}
          onChange={this.onChange}
          onSearch={this.onSearch}
          onClear={this.onClear}
          value={value}
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onMouseEnter={this.onMouseEnter}
          autoComplete="off"
        />
        {isOpen &&
          ReactDOM.createPortal(
            <StyledOptions
              spacing="none"
              optionsX={optionsX}
              optionsY={optionsY}
              optionsWidth={optionsWidth}
              openUpwards={openUpwards}
              ref={this.options}
            >
              {!items.length ? (
                <Button variant="list-item" disabled>
                  No options available
                </Button>
              ) : (
                items.map((item, idx) => (
                  <Button
                    variant="list-item"
                    key={item._id}
                    active={selectedIdx === idx}
                    onClick={() => this.onItemSelect(item)}
                  >
                    {renderItem(item)}
                  </Button>
                ))
              )}
            </StyledOptions>,
            document.getElementById('select-options')
          )}
      </StyledAutoSuggest>
    )
  }
}

AutoSuggest.propTypes = Object.assign({}, Search.propTypes, {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onItemSelect: PropTypes.func.isRequired,
  closeOnSelect: PropTypes.bool,
  renderItem: PropTypes.func
})

AutoSuggest.defaultProps = {
  renderItem: item => item.name
}

export default view(AutoSuggest)
