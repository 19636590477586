import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { store, view } from 'react-stax'
import Layout from '../../common/components/Layout'

const BUBBLE_RATIO = 30
const OPEN_RIGHT_THRESHOLD = 100

function getBubbleRatio({ openRight }) {
  return openRight ? 100 - BUBBLE_RATIO : BUBBLE_RATIO
}

const fadeIn = props => keyframes`
  from {
    opacity: 0;
    transform: translate(-${getBubbleRatio(props)}%, -100%) scale(0);
  }

  to {
    opacity: 1;
    transform: translate(-${getBubbleRatio(props)}%, -100%) scale(1);
  }
`

const StyledTooltip = styled.div`
  position: fixed;
  top: ${({ top }) => top - 10}px;
  left: ${({ left }) => left}px;
  transform: translate(-${getBubbleRatio}%, -100%);
  transform-origin: ${getBubbleRatio}% calc(100% + 10px);
  padding: 6px 10px;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.color.shadow} 0 5px 10px;
  visibility: visible;
  z-index: 1500;
  animation: ${fadeIn} .2s;
  max-width: 200;
  white-space: pre-wrap;
  user-select: none;
  cursor: default;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2;
  color: white;
  background-color: ${({ theme }) => theme.color.black};

  &:before, &:after {
    top: 100%;
    left: ${getBubbleRatio}%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: ${({ theme }) => theme.color.black};
    border-width: 7px;
    margin-left: -7px;
  }
  &:before {
    border-color: rgba(0, 0, 0, 0);
    border-width: 8px;
    margin-left: -8px;
  }
}
`

// if the mouse is still for this amount of time the tooltip will be shown
const OPEN_DELAY = 600

class Tooltip extends Component {
  top = 0
  left = 0

  store = store({
    open: false
  })

  onMouseMove = ev => {
    this.top = ev.clientY
    this.left = ev.clientX
    this.openRight = window.innerWidth - ev.clientX < OPEN_RIGHT_THRESHOLD

    if (!this.openId) {
      this.openId = setTimeout(() => (this.store.open = true), OPEN_DELAY)
    }
  }

  closeTooltip = () => {
    this.store.open = false
    clearTimeout(this.openId)
    this.openId = undefined
  }

  render() {
    const { children, message, ...rest } = this.props
    const { top, left, openRight } = this
    const { open } = this.store

    const isOpen = Boolean(open && message)

    // disabled buttons do not trigger mouseLeave or mouseOut events
    // we have to add a transparent overlay in these cases
    return (
      <div
        {...rest}
        style={{ display: 'flex' }}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.closeTooltip}
        onClick={this.closeTooltip}
      >
        {children}
        {isOpen &&
          ReactDOM.createPortal(
            <StyledTooltip top={top} left={left} openRight={openRight}>
              {message}
            </StyledTooltip>,
            document.getElementById('tooltip')
          )}
      </div>
    )
  }
}

Tooltip.propTypes = {
  message: PropTypes.string.isRequired,
  delay: PropTypes.number
}

Tooltip.defaultProps = {
  delay: 500
}

export default view(Tooltip)
