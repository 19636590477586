import React, { Component, Fragment } from 'react'
import { view, store } from 'react-stax'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Layout from '../../common/components/Layout'
import { ChevronDown } from '../icons'


const StyledHeader = styled(Layout)`
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  background-color: inherit;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 4px 10px;
  padding-right: 2px;

  &:hover {
    background-color: #eeeeff;
  }

  .heading {
    width: 100%;
  }

  svg {
    width: 13px;
    height: 13px;
  }

  .chevron {
    transform: rotate(${({ isOpen }) => (isOpen ? 0 : -90)}deg);
    transition: transform 0.12s;
    width: 16px;
    height: 16px;
  }
`
const StyledContent = styled.div`
  overflow: scroll;
`

class Hideable extends Component {
  onControlsClick = ev => {
    ev.stopPropagation()
  }

  render() {
    const { heading, controls, children, open, onToggle, ...rest } = this.props

    return (
      <>
        <StyledHeader variant="horizontal" spacing="dense" isOpen={open} onClick={onToggle}>
          <div className="heading">{heading}</div>
          <Layout variant="horizontal" spacing="tight">
            <Layout onClick={this.onControlsClick} variant="horizontal" spacing="tight">{controls}</Layout>
            <ChevronDown className="chevron" />
          </Layout>
        </StyledHeader>
        {open && (
          <StyledContent {...rest}>{children}</StyledContent>
        )}
      </>
    )
  }
}

export default view(Hideable)

Hideable.propTypes = {
  heading: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onToggle: PropTypes.func.isRequired
}

Hideable.defaultProps = {
  open: false
}
