import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { lighten } from 'polished'
import Tooltip from './Tooltip'

const StyledMenuItem = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.unit * 1.75}px
    ${({ theme }) => theme.spacing.unit * 2.25}px;
  white-space: pre;
  transition: all 0.2s;

  :hover,
  :active {
    color: ${({ theme, color }) => theme.utils.textColor(theme.color[color])};
  }

  :hover {
    background-color: ${props =>
      lighten(props.lighten, props.theme.color[props.color])};
  }

  :active {
    background-color: ${({ theme, color }) => theme.color[color]};
  }

  &.disabled {
    background-color: ${({ theme }) => theme.color.disabled} !important;
    color: ${({ theme }) => theme.color.white} !important;
    cursor: not-allowed;
  }
`

export default class MenuItem extends PureComponent {
  onClick = ev => {
    const { disabled, onClick } = this.props
    if (!disabled) {
      onClick(ev)
    }
  }

  render() {
    const { children, disabled, ...rest } = this.props
    let { color, tooltip, className } = this.props

    let lighten = 0.15
    if (color === 'primary') {
      color = 'highlight'
      lighten = 0.05
    }

    className = classNames(className, { disabled })

    const content = tooltip ? (
      <Tooltip message={tooltip}>{children}</Tooltip>
    ) : (
      children
    )

    return (
      <StyledMenuItem
        {...rest}
        className={className}
        color={color}
        lighten={lighten}
        onClick={this.onClick}
      >
        {content}
      </StyledMenuItem>
    )
  }
}

MenuItem.propTypes = {
  color: PropTypes.oneOf(['primary', 'danger', 'warning', 'success']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string
}

MenuItem.defaultProps = {
  color: 'primary'
}
