import React, { PureComponent } from 'react'
import { CustomPicker } from 'react-color'
import { Hue, Saturation, Alpha } from 'react-color/lib/components/common'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Close as CloseIcon } from '../icons'
import Swatch from './Swatch'
import Button from './Button'
import { notify } from './Notification'
import Layout from '../../common/components/Layout'
import InputConfirm from './InputConfirm'
import { parseToRgb, transparentize, rgb as toHex } from 'polished'

const StyledColorPicker = styled.div`
  position: relative;
  width: 285px;
  padding: 20px;
  padding-top: 0;

  .recent-colors {
    margin-bottom: 16px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 16px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

const StyledPicker = styled.div`
  position: relative;
  width: 16px;
  height: 170px;
  cursor: pointer;

  .pointer {
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(10, 10, 10, 0.5);
    cursor: grab;
  }

  &:active {
    cursor: grabbing;

    .pointer {
      cursor: grabbing;
    }
  }
`

const CustomColor = CustomPicker(props => (
  <Layout variant="horizontal">
    <StyledPicker style={{ width: 170 }}>
      <Saturation
        {...props}
        pointer={() => (
          <div className="pointer" style={{ left: -6, bottom: 6 }} />
        )}
      />
    </StyledPicker>
    <StyledPicker>
      <Hue
        {...props}
        direction="vertical"
        pointer={() => (
          <div className="pointer" style={{ left: 2, bottom: 6 }} />
        )}
      />
    </StyledPicker>
    <StyledPicker>
      <Alpha
        {...props}
        direction="vertical"
        pointer={() => (
          <div className="pointer" style={{ left: -1, bottom: 6 }} />
        )}
      />
    </StyledPicker>
  </Layout>
))

const hexRegex = /^#([0-9A-F]{3}){1,2}$/i

export default class ColorPicker extends PureComponent {
  onInputColorChange = color => {
    const { opacity } = this.props
    this.onChange({ color, opacity })
  }

  onInputOpacityChange = opacity => {
    const { color } = this.props
    this.onChange({ color, opacity })
  }

  onCustomChange = ({ hex, rgb }) => {
    this.onChange({ color: hex, opacity: rgb.a })
  }

  onChange = ({ color, opacity }) => {
    const { onChange, hexMode } = this.props

    // make sure that the opacity is converted to a number
    // (in case it is coming from an input field)
    opacity = Number(opacity)

    if (hexMode) {
      onChange({ color, opacity: Number(opacity.toPrecision(2)) })
    } else {
      // we have to use an inverse transparentize here
      // the opacify function of polished is not working with hex colors
      onChange({ color: transparentize(1 - opacity, color) })
    }
  }

  validateOpacity = value => {
    value = Number(value)
    if (isNaN(value)) {
      return 'Must be a number'
    }
    if (value < 0 || 1 < value) {
      return 'Must be between 0 - 1'
    }
  }

  validateColor = value => {
    if (!hexRegex.test(value)) {
      return 'Must be a hex color'
    }
  }

  render() {
    const {
      recentColors,
      onClose,
      onPreset,
      onChange,
      color,
      opacity,
      hexMode
    } = this.props

    const hasRecentColors = Boolean(recentColors.length)
    const { red, green, blue, alpha } = parseToRgb(color)
    const rgba = {
      r: red,
      g: green,
      b: blue,
      a: Number(hexMode ? opacity : alpha).toPrecision(2)
    }
    const hex = toHex({ red, green, blue })

    return (
      <StyledColorPicker>
        <Button variant="text" color="danger" onClick={onClose}>
          <CloseIcon className="close" />
        </Button>
        {hasRecentColors && (
          <div className="recent-colors">
            <h2>Recent Colors</h2>
            <Layout variant="grid" spacing="tight">
              {recentColors.map(({ color, opacity }, idx) => (
                <Swatch
                  key={idx}
                  color={color}
                  opacity={opacity}
                  onClick={this.onChange}
                />
              ))}
            </Layout>
          </div>
        )}
        <h2>Custom Color</h2>
        <Layout spacing="dense">
          <CustomColor color={rgba} onChange={this.onCustomChange} />
          <Layout variant="horizontal">
            <InputConfirm
              label="Color"
              value={hex}
              onChange={this.onInputColorChange}
              validator={this.validateColor}
            />
            <InputConfirm
              label="Opacity"
              value={rgba.a}
              onChange={this.onInputOpacityChange}
              validator={this.validateOpacity}
            />
          </Layout>
        </Layout>
      </StyledColorPicker>
    )
  }
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  opacity: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  recentColors: PropTypes.arrayOf(PropTypes.string),
  hexMode: PropTypes.bool
}

ColorPicker.defaultProps = {
  recentColors: []
}
