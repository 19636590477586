import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import { view } from 'react-stax'
import Layout from '../../common/components/Layout'
import { Close, Check } from '../icons'

const StyledSwitch = styled(Layout)`
  cursor: pointer;

  label {
    cursor: pointer;
    font-weight: ${({ compact }) => (compact ? 'initial' : 'bold')};
  }

  &.button:hover label {
    color: ${({ theme }) => theme.color.primary};
  }

  :hover .container {
    border: 1px solid ${({ theme }) => theme.color.primary};
  }

  .container {
    position: relative;
    height: 18px;
    max-width: 37px !important;
    min-width: 37px !important;
    border-radius: 9px;

    border: 1px solid ${({ theme }) => theme.color.black};

    .handle {
      z-index: 10;
      position: absolute;
      top: 1px;
      left: ${({ checked }) => (checked ? 20 : 1)}px;
      width: 14px;
      height: 14px;
      border-radius: 9px;
      background-color: ${({ theme, checked }) =>
        checked ? theme.color.primary : theme.color.lightGray};
      transition: all 0.2s;
    }

    svg {
      z-index: 1;
      position: absolute;
      top: 1px;
      width: 14px;
      height: 14px;

      &.open {
        left: 2px;
        color: ${({ theme }) => theme.color.primary};
      }

      &.close {
        left: 19px;
        color: ${({ theme }) => theme.color.disabled};
      }
    }

    &.disabled {
      border-color: ${({ theme }) => theme.color.disabled} !important;
    }
  }
`

class Switch extends Component {
  onClick = () => {
    const { checked, disabled, onChange } = this.props

    if (!disabled) {
      onChange(!checked)
    }
  }

  render() {
    let {
      label,
      required,
      disabled,
      checked,
      button,
      compact,
      ...rest
    } = this.props
    let { className } = this.props

    className = classNames(className, { disabled, button })
    if (button) {
      compact = true
    }

    const switchLabel = (
      <label>
        {label}
        {required && '*'}
      </label>
    )

    return (
      <StyledSwitch
        {...rest}
        variant="horizontal"
        spacing="tight"
        checked={checked}
        className={className}
        onClick={this.onClick}
        button={button}
        compact={compact}
      >
        {!button && switchLabel}
        <div className="container">
          <div className="handle" />
          {checked ? <Check className="open" /> : <Close className="close" />}
        </div>
        {button && switchLabel}
      </StyledSwitch>
    )
  }
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  compact: PropTypes.bool,
  button: PropTypes.bool
}

export default view(Switch)
