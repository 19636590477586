import React, { Component } from 'react'
import styled from 'styled-components'
import { store, view } from 'react-stax'
import { Confirm, Input, Layout, Button, Switch, notify } from './index'
import { Copy } from '../icons'

const ORIGIN = window.location.origin

const StyledStoryPublisher = styled(Confirm)`
  .instruction {
    margin: 0px;
    font-weight: bold;

    &.inline {
      white-space: nowrap;
    }
  }

  .autoplay-toggle {
    justify-content: space-between !important;
  }

  .separator {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    text-decoration: uppercase;

    .line {
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background-color: ${({ theme }) => theme.color.background};
    }

    .text {
      color: ${({ theme }) => theme.color.lightGray};
    }
  }

  .snippet {
    position: relative;
    height: 40px;

    &.inline {
      width: 100%;
    }

    input {
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.color.background};
      padding-left: 10px;
      padding-right: 50px;
      border-radius: 5px;
      overflow: scroll;
      font-size: inherit;
      border: none;
      outline: none;
      text-overflow: ellipsis;
    }

    .button {
      position: absolute;
      right: 0;
      min-width: 40px;
      max-width: 40px;
      height: 100%;
      padding: 0;
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }
`

class StoryPublisher extends Component {
  idRef = React.createRef()
  scriptRef = React.createRef()
  divRef = React.createRef()

  store = store({
    autoPlay: false
  })

  onCopyStoryId = () => {
    this.onCopy(this.idRef)
  }

  onCopyEmbedScript = () => {
    this.onCopy(this.scriptRef)
  }

  onCopyStoryDiv = () => {
    this.onCopy(this.divRef)
  }

  onCopy = ref => {
    const input = ref.current
    input.select()
    document.execCommand('copy')
    notify({ message: 'Copied to clipboard', color: 'success' })
  }

  onToggleAutoPlay = () => {
    this.store.autoPlay = !this.store.autoPlay
  }

  onSubmit = async () => {
    const { story, onSubmit } = this.props
    const isPublished = Boolean(story.publishedBy)

    await onSubmit()

    notify({
      message: isPublished
        ? 'Your story is republished!'
        : 'Your story is published!',
      color: 'success'
    })
  }

  render() {
    const { story, ...rest } = this.props
    const { autoPlay } = this.store

    const id = story._id
    const script = `<script src="${ORIGIN}/embed.js" defer></script>`
    const div = `<div data-map data-id="${story._id}" ${
      autoPlay ? 'data-autoplay' : ''
    }></div>`

    const isPublished = Boolean(story.publishedBy)
    const isUpToDate = isPublished && !story.changedSincePublish
    const publishText = isUpToDate
      ? 'PUBLISHED'
      : isPublished
      ? 'REPUBLISH'
      : 'PUBLISH'

    return (
      <StyledStoryPublisher
        {...rest}
        onSubmit={this.onSubmit}
        submitText={publishText}
        cancelText="CANCEL"
        disabled={isUpToDate}
      >
        <Layout variant="horizontal" spacing="dense">
          <p className="instruction inline">Story id:</p>
          <div className="snippet inline">
            <input value={id} readonly ref={this.idRef} />
            <Button className="button" onClick={this.onCopyStoryId}>
              <Copy className="icon" />
            </Button>
          </div>
        </Layout>
        <Layout className="separator" variant="horizontal" spacing="dense">
          <div className="line" />
          <div className="text">ADVANCED</div>
          <div className="line" />
        </Layout>
        <Layout spacing="dense">
          <p className="instruction">
            1. Copy this into the head section of your page once.
          </p>
          <div className="snippet">
            <input value={script} readonly ref={this.scriptRef} />
            <Button className="button" onClick={this.onCopyEmbedScript}>
              <Copy className="icon" />
            </Button>
          </div>
        </Layout>
        <Switch
          className="autoplay-toggle"
          label="2. Should your story start automatically?"
          checked={autoPlay}
          onChange={this.onToggleAutoPlay}
        />
        <Layout spacing="dense">
          <p className="instruction">
            3. Copy this where you would like the story to be displayed on your
            page.
          </p>
          <div className="snippet">
            <input value={div} readonly ref={this.divRef} />
            <Button className="button" onClick={this.onCopyStoryDiv}>
              <Copy className="icon" />
            </Button>
          </div>
        </Layout>
      </StyledStoryPublisher>
    )
  }
}

export default view(StoryPublisher)
