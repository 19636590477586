import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from './Button'
import Form from './Form'

const StyledConfirm = styled.div`
  .buttons {
    display: flex;
    margin: -${({ theme }) => theme.spacing.unit * 4}px;
    width: calc(100% + ${({ theme }) => theme.spacing.unit * 8}px);
    margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
    height: 40px;

    > * {
      flex-basis: 50%;
      height: 100%;
      border-radius: 0;

      :nth-child(1) {
        border-bottom-left-radius: 3px;
      }
      :nth-child(2) {
        border-bottom-right-radius: 3px;
      }
    }
  }
`

function Confirm({
  title,
  open,
  color,
  submitText,
  cancelText,
  onCancel,
  children,
  errors,
  disabled,
  ...rest
}) {
  return (
    <StyledConfirm>
      <Form {...rest} errors={errors}>
        {children}
        <div className="buttons">
          <Button variant="flat" color="white" onClick={onCancel}>
            {cancelText}
          </Button>
          <Button
            variant="flat"
            color={color}
            type="submit"
            errors={errors}
            disabled={disabled}
          >
            {submitText}
          </Button>
        </div>
      </Form>
    </StyledConfirm>
  )
}

export default memo(Confirm)

Confirm.propTypes = {
  submitText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'success',
    'danger',
    'warning',
    'white',
    'black'
  ])
}

Confirm.defaultProps = {
  color: 'primary'
}
