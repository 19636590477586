import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _debounce from 'lodash/debounce'
import _throttle from 'lodash/throttle'
import { store, view } from 'react-stax'
import { Close as ClearIcon, Search as SearchIcon } from '../icons'
import Input from './Input'
import Button from './Button'
import Layout from '../../common/components/Layout'

class Search extends Component {
  get value() {
    const { store, props } = this
    return 'value' in props ? props.value : store.value
  }

  constructor(props) {
    super(props)

    this.store = store({
      value: props.defaultValue || ''
    })

    const { chokeFn, chokeDuration } = props
    if (chokeFn === 'throttle') {
      this.onSearch = _throttle(this.onSearch, chokeDuration)
    } else if (chokeFn === 'debounce') {
      this.onSearch = _debounce(this.onSearch, chokeDuration)
    }
  }

  onKeyDown = ev => {
    const { onKeyDown } = this.props
    if (onKeyDown) {
      onKeyDown(ev)
    }

    if (ev.key === 'Escape') {
      this.onClear()
    }
  }

  onChange = ev => {
    const { onChange } = this.props
    if (onChange) {
      onChange(ev)
    }
    this.store.value = ev.target.value
    this.onSearch()
  }

  onSearch = () => {
    this.props.onSearch(this.value)
  }

  onClear = () => {
    const { onClear, onSearch } = this.props
    onSearch()
    if (onClear) {
      onClear()
    }
    this.store.value = ''
  }

  render() {
    const { widgets, ...rest } = this.props

    let finalWidgets = this.value ? (
      <Button variant="text" color="danger" onClick={this.onClear}>
        <ClearIcon />
      </Button>
    ) : (
      <Button variant="text" onClick={this.onSearch}>
        <SearchIcon />
      </Button>
    )

    if (widgets) {
      finalWidgets = (
        <>
          {finalWidgets}
          {widgets}
        </>
      )
    }

    return (
      <Input
        {...rest}
        value={this.value}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        widgets={finalWidgets}
      />
    )
  }
}

export default view(Search)

Search.propTypes = {
  onSearch: PropTypes.func.isRequired,
  chokeFn: PropTypes.oneOf(['throttle', 'debounce']),
  chokeDuration: PropTypes.number
}

Search.defaultProps = {
  chokeDuration: 300
}
