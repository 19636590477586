import React, { forwardRef } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const spacingUnits = {
  none: 0,
  tight: 1,
  dense: 2,
  default: 4,
  light: 6
}

function getSpacing({ theme, spacing }) {
  const units = spacingUnits[spacing]
  return theme.spacing.unit * units
}

const StyledLayout = styled.div`
  display: flex;

  &.horizontal {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    > * {
      &:not(:last-child) {
        margin-right: ${getSpacing}px;
      }
    }
  }

  &.vertical {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;

    > * {
      &:not(:last-child) {
        margin-bottom: ${getSpacing}px;
      }

      &:not(button) {
        min-width: 100%;
      }
    }

    > button[type='submit'] {
      margin-top: ${props => getSpacing(props) * 0.5}px;
    }
  }

  &.grid {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    :not(:empty) {
      margin-left: -${props => getSpacing(props) / 2}px;
      margin-right: -${props => getSpacing(props) / 2}px;
    }

    > * {
      margin: ${props => getSpacing(props) / 2}px;
    }
  }

  &.center {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    > * {
      margin-right: ${getSpacing}px;

      &:last-child,
      &:only-child {
        margin-right: 0;
      }
    }
  }
`

function Layout(
  { spacing, variant, className, element, children, ...rest },
  ref
) {
  className = classNames(className, variant)

  return (
    <StyledLayout
      {...rest}
      ref={ref}
      as={element}
      spacing={spacing}
      className={className}
    >
      {children}
    </StyledLayout>
  )
}

export default (Layout = forwardRef(Layout))

Layout.propTypes = {
  variant: PropTypes.oneOf(['vertical', 'horizontal', 'grid', 'center']),
  spacing: PropTypes.oneOf(Object.keys(spacingUnits)),
  element: PropTypes.string
}

Layout.defaultProps = {
  spacing: 'default',
  variant: 'vertical',
  element: 'div'
}
