import React, { Component } from 'react'
import { store, view } from 'react-stax'
import PropTypes from 'prop-types'
import { Input, Confirm } from '/'

class StoryDuplicator extends Component {
  constructor(props) {
    super(props)

    this.store = store({
      data: { title: this.props.title || '' },
      errors: {}
    })
  }

  onChange = ev => {
    const { name, value } = ev.target
    this.store.data[name] = value
  }

  onSubmit = async () => {
    const { title } = this.store.data
    await this.props.onDuplicate({ title })
  }

  render() {
    const { onDuplicate, onCancel, ...rest } = this.props
    const { data, errors } = this.store

    return (
      <Confirm
        {...rest}
        submitText="SAVE COPY"
        cancelText="CANCEL"
        onSubmit={this.onSubmit}
        onCancel={onCancel}
        schema="stories.duplicate.body"
        data={data}
        errors={errors}
      >
        <Input
          label="Title"
          name="title"
          value={data.title}
          error={errors.title}
          onChange={this.onChange}
          required
          autoFocus
        />
      </Confirm>
    )
  }
}

export default view(StoryDuplicator)

StoryDuplicator.propTypes = {
  onDuplicate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
