import React, { Component } from 'react'
import { view } from 'react-stax'
import { Router, route } from 'react-stax'
import Loader, { startLoading, stopLoading } from './components/Loader'
import { Notification, notify } from './components'
import Login from './pages/Login'
import Register from './pages/Register'
import userStore, { roles } from './stores/user'

const publicPages = new Set(['login', 'register'])

// try to somehow trigger a loading here!! (maybe let UI updates happen during routing)
// could help with next stax version
async function onRoute({ toPage, fromPage }) {
  startLoading()
  let nextPage

  try {
    if (!publicPages.has(toPage) && !userStore.profile) {
      route({ to: '/login' })
    } else if (publicPages.has(toPage) && userStore.profile) {
      if (toPage === 'register') {
        userStore.logout()
        notify({ message: 'You were automatically logged out' })
      } else {
        route({ to: '/admin' })
      }
    } else if (toPage === 'admin') {
      const [
        { default: AdminPage },
        { default: adminStore }
      ] = await Promise.all([import('./pages/Admin'), import('./stores/admin')])
      await adminStore.init()
      nextPage = <AdminPage page="admin" />
    } else if (toPage === 'story') {
      const [
        { default: StoryPage },
        { default: storyStore }
      ] = await Promise.all([import('./pages/Story'), import('./stores/story')])
      // reinit is possible when the user switches between two stories
      // for example when they duplicate a story
      await storyStore[fromPage === 'story' ? 'reinit' : 'init']()
      nextPage = <StoryPage page="story" />
    } else if (toPage === 'preview') {
      const [
        { default: PreviewPage },
        { default: previewStore }
      ] = await Promise.all([
        import('./pages/Preview'),
        import('./stores/preview')
      ])
      await previewStore.init()
      nextPage = <PreviewPage page="preview" />
    } else if (toPage === 'users') {
      if (userStore.profile.role === roles.ADMIN) {
        const [
          { default: UsersPage },
          { default: usersStore }
        ] = await Promise.all([
          import('./pages/Users'),
          import('./stores/users')
        ])
        await usersStore.init()
        nextPage = <UsersPage page="users" />
      } else {
        route({ to: '/admin' })
      }
    }

    // do the teardown after the init to avoid flickering on the leaving page
    if (fromPage === 'story') {
      const { default: storyStore } = await import('./stores/story')

      // teardown should accour after the new page is rendered
      // to do not re-render the story page with a half toren store
      // which would cause a crash
      if (toPage !== 'story') {
        // leave some time for React to remove the story page and render the new page
        // a rapid clicker could cause the teardown to kick in halway during initing
        // with a quick navigation -> back navigation combo
        setTimeout(() => storyStore.teardown())
      }
    }
  } catch (err) {
    route({ to: '/admin' })
    notify({ message: err.message, color: 'danger' })
    console.error('Page transition error', err)
  } finally {
    stopLoading()
    return nextPage
  }
}

class App extends Component {
  componentDidCatch(error, errorInfo) {
    console.error('React render error', error, errorInfo)
    route({ to: '/admin' })
    notify({ message: 'An unexpected error occured', color: 'danger' })
  }

  render() {
    // heavier routes are virtualized and lazy loaded in the onRoute method
    return (
      <>
        <Loader />
        <Router defaultPage="admin" onRoute={onRoute}>
          <Login page="login" />
          <Register page="register" />
        </Router>
        <Notification />
      </>
    )
  }
}

export default view(App)
