import React, { memo } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 0.8; }
`

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 120;
  background-color: ${({ theme }) => theme.color.black};
  opacity: 0.8;
  animation: ${fadeIn} 0.1s;
`

function Backdrop() {
  return ReactDOM.createPortal(
    <StyledBackdrop />,
    document.getElementById('backdrop')
  )
}

export default memo(Backdrop)
