import React, { Component, Fragment } from 'react'
import { view, store } from 'react-stax'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Check, Close } from '../icons'
import Layout from '../../common/components/Layout'
import Button from './Button'
import Input from './Input'

const StyledButtons = styled(Layout)`
  height: 14px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 7px #ccc;
  overflow: hidden;

  svg {
    width: 12px;
    height: 12px;
  }
  .button {
    font-size: 16px;
    width: 15px;
    height: 17px;
  }
  .left-button {
    border-right: 1px solid #ccc;
  }
`

class InputConfirm extends Component {
  input = React.createRef()

  constructor(props) {
    super(props)

    this.store = store({
      isFocused: false,
      value: props.value,
      prevValue: props.value
    })
  }

  static deriveStoresFromProps(props, store) {
    // save the new values if the props changed
    if (props.value !== store.prevValue) {
      store.value = props.value
      store.prevValue = props.value
    }
  }

  onChange = ev => {
    this.store.error = undefined
    this.store.value = ev.target.value
  }

  onFocus = () => {
    this.store.isFocused = true
  }

  onBlur = () => {
    this.store.isFocused = false
    this.store.value = this.store.prevValue
  }

  onApply = () => {
    const { onChange, validator } = this.props
    const { value } = this.store

    const error = validator(value)
    if (!error) {
      onChange(value)
      this.input.current.blur()
    } else {
      this.store.error = error
    }
  }

  onEscape = () => {
    this.store.value = this.store.prevValue
    this.input.current.blur()
  }

  onKeyDown = ev => {
    if (ev.key === 'Enter') {
      this.onApply()
    } else if (ev.key === 'Escape') {
      this.onEscape()
    }
  }

  render() {
    const { isFocused, value, error } = this.store

    return (
      <Input
        {...this.props}
        innerRef={this.input}
        value={value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onKeyDown={this.onKeyDown}
        error={error}
        widgets={
          isFocused && (
            <StyledButtons variant="center" spacing="none">
              <Button
                variant="text"
                layout="center"
                color="danger"
                className="button left-button"
                onClick={this.onEscape}
              >
                <Close />
              </Button>
              <Button
                variant="text"
                layout="center"
                color="success"
                className="button"
                onClick={this.onApply}
              >
                <Check />
              </Button>
            </StyledButtons>
          )
        }
      />
    )
  }
}

export default view(InputConfirm)

InputConfirm.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

InputConfirm.defaultProps = {
  value: '',
  validator: () => {}
}
