import { ReactComponent as AlignCenter } from './align-center.svg'
import { ReactComponent as AlignJustify } from './align-justify.svg'
import { ReactComponent as AlignLeft } from './align-left.svg'
import { ReactComponent as AlignRight } from './align-right.svg'
import { ReactComponent as Anchor } from './anchor.svg'
import { ReactComponent as Arrow } from './arrow.svg'
import { ReactComponent as ArrowHead } from './arrow-head.svg'
import { ReactComponent as Back } from './back.svg'
import { ReactComponent as Bold } from './bold.svg'
import { ReactComponent as Book } from './book.svg'
import { ReactComponent as Check } from './check.svg'
import { ReactComponent as ChevronDown } from './chevron-down.svg'
import { ReactComponent as ChevronUp } from './chevron-up.svg'
import { ReactComponent as ChevronLeft } from './chevron-left.svg'
import { ReactComponent as ChevronRight } from './chevron-right.svg'
import { ReactComponent as Circle } from './circle.svg'
import { ReactComponent as Close } from './close.svg'
import { ReactComponent as Command } from './command.svg'
import { ReactComponent as Copy } from './copy.svg'
import { ReactComponent as Edit } from './edit.svg'
import { ReactComponent as Eye } from './eye.svg'
import { ReactComponent as EyeOff } from './eye-off.svg'
import { ReactComponent as Folder } from './folder.svg'
import { ReactComponent as Globe } from './globe.svg'
import { ReactComponent as Heart } from './heart.svg'
import { ReactComponent as Image } from './image.svg'
import { ReactComponent as Import } from './import.svg'
import { ReactComponent as Italic } from './italic.svg'
import { ReactComponent as Layers } from './layers.svg'
import { ReactComponent as Line } from './line.svg'
import { ReactComponent as LineArrow } from './line-arrow.svg'
import { ReactComponent as LineSmooth } from './line-smooth.svg'
import { ReactComponent as List } from './list.svg'
import { ReactComponent as ListNumbered } from './list-numbered.svg'
import { ReactComponent as Loader } from './loader.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as Map } from './map.svg'
import { ReactComponent as Menu } from './menu.svg'
import { ReactComponent as Minus } from './minus.svg'
import { ReactComponent as Move } from './move.svg'
import { ReactComponent as Paste } from './paste.svg'
import { ReactComponent as Play } from './play.svg'
import { ReactComponent as PlayIn } from './play-in.svg'
import { ReactComponent as PlayOut } from './play-out.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Poi } from './poi.svg'
import { ReactComponent as MultiPoi } from './multi-poi.svg'
import { ReactComponent as PointPrimary } from './point-primary.svg'
import { ReactComponent as PointSecondary } from './point-secondary.svg'
import { ReactComponent as Polygon } from './polygon.svg'
import { ReactComponent as PolygonSmooth } from './polygon-smooth.svg'
import { ReactComponent as Preview } from './preview.svg'
import { ReactComponent as Rectangle } from './rectangle.svg'
import { ReactComponent as Redo } from './redo.svg'
import { ReactComponent as Save } from './save.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as Sound } from './sound.svg'
import { ReactComponent as Text } from './text.svg'
import { ReactComponent as Trash } from './trash.svg'
import { ReactComponent as Triangle } from './triangle.svg'
import { ReactComponent as Underline } from './underline.svg'
import { ReactComponent as Undo } from './undo.svg'
import { ReactComponent as Unlock } from './unlock.svg'
import { ReactComponent as Upload } from './upload.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as Video } from './video.svg'

// TODO: improve re-export with
// export { ReactComponent as IconName } from './icon-name.svg'
// investigate why it does not work like that
export {
  AlignLeft,
  AlignRight,
  AlignCenter,
  AlignJustify,
  Anchor,
  Arrow,
  ArrowHead,
  Back,
  Bold,
  Book,
  Check,
  ChevronUp,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Circle,
  Close,
  Command,
  Copy,
  Edit,
  Eye,
  EyeOff,
  Folder,
  Globe,
  Heart,
  Image,
  Import,
  Italic,
  Layers,
  Line,
  LineArrow,
  LineSmooth,
  List,
  ListNumbered,
  Lock,
  Loader,
  Map,
  Menu,
  Minus,
  Move,
  Paste,
  Play,
  PlayIn,
  PlayOut,
  Plus,
  Poi,
  MultiPoi,
  PointPrimary,
  PointSecondary,
  Polygon,
  PolygonSmooth,
  Preview,
  Rectangle,
  Redo,
  Save,
  Search,
  Sound,
  Text,
  Trash,
  Triangle,
  Underline,
  Unlock,
  User,
  Undo,
  Upload,
  Video
}

export const featureIcons = {
  Group: Folder,
  Point: Poi,
  GroupPoint: MultiPoi,
  Icon: Anchor,
  LineString: Line,
  LineStringSmooth: LineSmooth,
  Polygon,
  PolygonSmooth,
  Circle,
  Rectangle,
  Arrow,
  Text,
  Label: Text,
  Note: Text,
  GroupLabel: Text,
  IconLabel: Text,
  PolyLabel: Text,
  LineLabel: Text,
  Image,
  Video,
  Sound,
  Trash
}
