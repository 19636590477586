import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styled from 'styled-components'
import Layout from '../../common/components/Layout'

const StyledInput = styled.div`
  position: relative;
  transition: color 0.2s, border-color 0.2s;
  width: 100%;

  .label {
    white-space: pre;
    font-weight: ${({ compact }) => (compact ? 'initial' : 'bold')};
  }

  .input {
    width: 100%;
    border-bottom: 1px solid ${({ theme, color }) => theme.color[color]};

    &:hover {
      border-color: ${({ theme, focusColor }) => theme.color[focusColor]};
    }

    input {
      width: 100%;
      outline: none;
      border: none;
      margin: 0;
      background-color: inherit;
      font-size: inherit;
      font-family: inherit;
      padding: 0 ${({ theme }) => theme.spacing.unit}px;
      transition: all 0.1s ease-in-out;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all 0.1s;
    }

    .widgets {
      bottom: 2px;
      right: 0px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .subtext {
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 11px;
    color: ${({ theme, color }) => theme.color[color]};
    text-align: right;
    margin-top: 2px;
  }
`

export default class Input extends Component {
  state = {}

  onChange = ev => {
    const { onChange } = this.props
    const { target } = ev

    // restore cursor after the update
    // to avoid a react bug that jumps to end of input after onChange events
    this.input = ev.target
    this.caretPosition = this.input.selectionStart
    if (onChange) {
      onChange(ev)
    }
  }

  componentDidUpdate() {
    if (this.input) {
      try {
        // some input type fields can't use range selection
        // like email type
        this.input.setSelectionRange(this.caretPosition, this.caretPosition)
      } catch (err) {}
    }
  }

  render() {
    const {
      label,
      required,
      error,
      correct,
      layout,
      spacing,
      widgets,
      compact,
      innerRef,
      ...rest
    } = this.props

    const color = error ? 'danger' : correct ? 'success' : 'text'
    const focusColor = color === 'text' ? 'primary' : color
    const subText = error || correct

    const labelLayout = compact ? 'horizontal' : 'vertical'
    const labelSpacing = compact ? 'tight' : 'none'

    return (
      <StyledInput
        color={color}
        focusColor={focusColor}
        variant={layout}
        spacing={spacing}
        compact={compact}
      >
        <Layout spacing="none" variant={labelLayout} spacing={labelSpacing}>
          {label && (
            <label className="label">
              {label}
              {required && '*'}
              {!required && ':'}
            </label>
          )}
          <Layout variant="horizontal" className="input" spacing="none">
            <input
              {...rest}
              onChange={this.onChange}
              onMouseDown={this.onMouseDown}
              ref={innerRef}
            />
            {widgets && (
              <Layout variant="horizontal" className="widgets" spacing="tight">
                {widgets}
              </Layout>
            )}
          </Layout>
        </Layout>
        {subText && <div className="subtext">{subText}</div>}
      </StyledInput>
    )
  }
}

Input.propTypes = {
  error: PropTypes.string,
  widgets: PropTypes.element,
  compact: PropTypes.bool
}

Input.defaultProps = {
  variant: 'vertical',
  spacing: 'tight',
  widgets: null
}
