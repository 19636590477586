import React, { Component } from 'react'
import styled from 'styled-components'
import { view, store, route } from 'react-stax'
import userStore from '../../stores/user'
import { Input, Form, Button, Layout } from '../../components'
import { User as UserIcon } from '../../icons'

const StyledLogin = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;

  svg {
    color: ${({ theme }) => theme.color.black};
    width: 60px;
    height: 60px;
  }

  .form {
    width: 300px;
  }

  .to-register {
    cursor: pointer;
    color: ${({ theme }) => theme.color.lightGray};
    white-space: pre;
    text-align: center;
    line-height: 1.5;
  }

  .buttons {
    > * {
      flex: 1;
    }
  }
`

const demoUser = { email: 'demo@gmail.com', password: 'demo_user' }

class Login extends Component {
  store = store({
    data: {
      email: '',
      password: ''
    },
    errors: {}
  })

  onChange = ev => {
    const { name, value } = ev.target
    this.store.data[name] = value
  }

  onSubmit = async () => {
    await userStore.login(this.store.data)
    route({ to: 'admin' })
  }

  onDemo = async () => {
    await userStore.login(demoUser)
    route({ to: 'admin' })
  }

  render() {
    const { data, errors } = this.store

    return (
      <StyledLogin>
        <Form
          className="form"
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          errors={errors}
          data={data}
          schema="users.login"
        >
          <UserIcon />
          <Input
            name="email"
            value={data.email}
            error={errors.email}
            label="Email Address"
            required
            autoFocus
            autoComplete="email"
          />
          <Input
            name="password"
            value={data.password}
            error={errors.password}
            type="password"
            label="Password"
            required
            autoComplete="current-password"
          />
          <Layout variant="horizontal" className="buttons">
            <Button variant="raised" type="submit" errors={errors}>
              LOG IN
            </Button>
            <Button
              variant="raised"
              onClick={this.onDemo}
              className="demo-button"
            >
              TRY THE DEMO
            </Button>
          </Layout>
        </Form>
      </StyledLogin>
    )
  }
}

export default view(Login)
