import React, { memo } from 'react'
import { Upload, Check } from '../icons'
import Button from './Button'

function PublishButton({ isPublished, isUpToDate, ...rest }) {
  // only count up to date stories if they were already published once
  isUpToDate = isPublished && isUpToDate

  const color = isUpToDate ? 'success' : 'primary'
  const Icon = isUpToDate ? Check : Upload
  const text = isUpToDate ? 'Published' : isPublished ? 'Republish' : 'Publish'

  return (
    <Button variant="text" color={color} {...rest}>
      <Icon />
      <span>{text}</span>
    </Button>
  )
}

export default memo(PublishButton)
