import styled from 'styled-components'

export default styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.background};
  z-index: 80;
  padding: 0 20px;
  height: 40px;
  font-size: 14px;

  svg {
    width: 20px;
  }
`
